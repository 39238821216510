@import "../../assets/scss/_variables";

.member-header {
    .profileImg {
        position: relative;
        overflow: hidden;

        label {
            margin: 0 0 0 0;
        }

        > .img-box {
            @include breakpoint(lg) {
                width: 180px;
            }
        }

        > .controls-top {
            position: absolute;
            top: 5px;
            left: 5px;
        }

        > input[type=file] {
            display: none;
        }

        > .controls {
            position: absolute;
            left: 0;
            bottom: 0;
            text-align: center;
            width: 100%;
            background: rgba(0,0,0,0.5);
            vertical-align: middle;
            padding: 5px 0 0 0;

            .label {
                float: left;
            }
        }

        > .loading {
            height: 180px;
            line-height: 180px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3);
            > .spinner {
                margin: 0 auto 0 auto;

            }
        }

        .action {
            padding: 0px 10px 0px 10x;
            margin: 0 10px 0 10px;
            cursor: pointer;
            display: inline-block;
            &:hover,
            &:active {
                svg {
                    fill: #f5658f;
                }
                color: #f5658f;
            }
        }

        >.controls.slide-in {
            bottom: -100%;
            transition-property: bottom;
            transition-duration: 0.2s;
        }

        &:hover,
        &:active,
        &:focus {
            .controls.slide-in {
                bottom: 0;
            }
        }
    }
    .roleThumbnail {
        img {
            width: 26px;
            height: 26px;
        }
    }
    .socialThumbnail {
        img {
            width: 24px;
            height: 24px;
        }
    }
}

.member-content {
    .achievement-list {
        .achievement {
            &:hover, &:focus, &:active {
                .content {
                    .number {
                        color: rgb(255, 64, 129);
                    }
                }
            }
            .content {
                color: #2799ab;
                border-bottom: solid 1px #d9d9d9;
                .number {
                    color: #37474f;
                }
                @include breakpoint(md) {
                    border-bottom: none;
                }
            }
            &.hosted {
                .content {
                    border-bottom: none;
                }
            }
            @include breakpoint(md) {
                &.joined {
                    border-left: solid 1px #d9d9d9;
                    border-right: solid 1px #d9d9d9;
                }
            }
        }
    }
}