.event-disabled {
    opacity: 0.5;
}
.event{
    &:hover, &:active, &:focus{
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    &:hover {
        .content {
            background: rgba(158,158,158, 0.20);
            .title {
                color: #f5658f;
            }
            .action-blcok {
                .join-btn {
                    background-color: #FB8C00;
                }
            }
        }
    }
    .content{
        position: relative;
        background-color: #ffffff;
        height: 265px;
        .title{
            color: #2799ab;}
        .description{
            color: #4a4a4a;
            height: 150px;
            overflow: hidden;
            @media only screen and (min-width: 992px){
                height: 170px;}}
        .action-blcok{
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            .join-btn{
                background: #f4a621;
                color: #ffffff;
                text-align: center;
                font-size: 14px;
                &.disabled{
                    background-color: rgba(158,158,158, 0.20);
                    color: rgba(0,0,0,0.26);}}}}}