.jumbotron {
  height: 30vh;
  background: url('../../assets/image/jumbotron.jpg') center 30% no-repeat;
  background-size: cover;
  @include breakpoint(md) {
    height: 50vh;
  }
  @include breakpoint(lg) {
    height: 60vh;
  }
  .search-bar{
    background-color: rgba(0, 0, 0, 0.4)
  }
}

.jumbotron.minimized {
  height: auto;
  padding-top: 1vh;
}

.member-type{
  height: 580px;
  border-width: 3px!important;
}

.support {
    margin-top: 100px;
    margin-bottom: 100px;
    a:hover, a:focus, a:active {
        opacity: 0.6;
    }
    img{
        max-width: 200px;
    }
}
