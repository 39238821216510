#modalLogin {
    .modal-header {
        .login-link {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .modal-body {
        .social-btn {
            color: white;
            letter-spacing: 0.1rem;
            &:active, &:visited, &:focus, &:hover {
                color: white;
                text-decoration: none;
            }
            &.fb {
                background-color: #19579d;
            }
            &.google {
                background-color: #d34836;
            }
            &.linkedin {
                background-color: #4875B4;
            }
        }
    }
}