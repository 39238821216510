.spinner {
    vertical-align: middle;
  }

  .spinner > div {
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;

  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }


.spinner2 {
  display: inline-block;
  border: 3px solid rgba(0,0,0,.2);
  border-radius: 50%;
  border-top-color: #555;
  animation: spinner2_spin 0.5s ease-in-out infinite;
  -webkit-animation: spinner2_spin 0.5s ease-in-out infinite;
}

@keyframes spinner2_spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spinner2_spin {
  to { -webkit-transform: rotate(360deg); }
}

.small-spinner {
  border: 3px solid lightgray; /* Light grey */
  border-top: 3px solid lightcoral; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 0.5s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}