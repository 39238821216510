$black-grey:                #252e37;
$orange:                    #ffc72c;
$pink:                      #f5658f;
$green:                     #2799ab;
$dark-blue:                 #242d36;
$light-blue:                #b1c5ce;
$grey: (
  level-1:                  #fafafa,
  level-2:                  #f5f5f5,
  level-3:                  #d9d9d9,
  level-4:                  #a5aaad,
  level-5:                  #87898a,
);

$theme-colors: (
  primary:                  $orange,
  secondary:                $pink,
  dark:                     $black-grey,
  grey-1:                   map-get($grey, level-1),
  grey-2:                   map-get($grey, level-2),
  grey-3:                   map-get($grey, level-3),
  grey-4:                   map-get($grey, level-4),
  grey-5:                   map-get($grey, level-5),
  green-light:              $green,
  dark-blue:                $dark-blue,
  light-blue:               $light-blue
);

$body-bg:                   map-get($grey, level-1);
$font-family-base:          'Open Sans', sans-serif;
$body-color:                $black-grey;

/* custom RWD setting*/
@mixin breakpoint($point) {
  @if ($point == xl) {
    @media only screen and (min-width: 1200px) {
      @content
    }
  }
  @else if ($point == lg) {
    @media only screen and (min-width: 992px) {
      @content
    }
  }
  @else if ($point == md) {
    @media only screen and (min-width: 768px) {
      @content
    }
  }
  @else if ($point == sm) {
    @media only screen and (min-width: 576px) {
      @content
    }
  }
  @else if ($point == i7mobile) {
    @media only screen and (min-width: 375px) {
      @content
    }
  }
}

/* hex background color to rgb with opacity */
@mixin background-opacity($color, $opacity: 1) {
    background: $color;
    background: rgba($color, $opacity);
}



/* z-index mangment*/
$z-layers: (
  HEADER_ACTIVE: 10,
  SIDE_MENU: 1,
);
