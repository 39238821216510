/*
|--------------------------------------------------------------------------
| Custom scss main entry
|--------------------------------------------------------------------------
|
| Note only import others scss file and hepler style there.
|
*/
@import "_variables";

/* Bootstrap4 */
@import "~bootstrap/scss/bootstrap";

/* Lato Font */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

@import "./animations.scss";
@import "./inputs.scss";
@import "./login.scss";
@import "./home.scss";
@import "./events.scss";
@import "./members.scss";
@import "./spinner.scss";

/* Custom Font Size */
$MAX_COUSTOM_FONT: 80;
$MIN_COUSTOM_FONT: 8;
@mixin font-size-list {
  @for $i from $MIN_COUSTOM_FONT through $MAX_COUSTOM_FONT {
    .font-size-#{$i} {
      font-size: #{$i}px
    }
  }
}

@mixin font-size-list-i7mobile {
  @for $i from $MIN_COUSTOM_FONT through $MAX_COUSTOM_FONT {
    @include breakpoint(i7mobile) {
      .font-size-i7-#{$i} {
        font-size: #{$i}px
      }
    }
  }
}

@mixin font-size-list-sm {
  @for $i from $MIN_COUSTOM_FONT through $MAX_COUSTOM_FONT {
    @include breakpoint(sm) {
      .font-size-sm-#{$i} {
        font-size: #{$i}px
      }
    }
  }
}

@mixin font-size-list-md {
  @for $i from $MIN_COUSTOM_FONT through $MAX_COUSTOM_FONT {
    @include breakpoint(md) {
      .font-size-md-#{$i} {
        font-size: #{$i}px
      }
    }
  }
}

@mixin font-size-list-lg {
  @for $i from $MIN_COUSTOM_FONT through $MAX_COUSTOM_FONT {
    @include breakpoint(lg) {
      .font-size-lg-#{$i} {
        font-size: #{$i}px
      }
    }
  }
}

@mixin font-size-list-xl {
  @for $i from $MIN_COUSTOM_FONT through $MAX_COUSTOM_FONT {
    @include breakpoint(xl) {
      .font-size-xl-#{$i} {
        font-size: #{$i}px
      }
    }
  }
}

@include font-size-list;
@include font-size-list-i7mobile;
@include font-size-list-sm;
@include font-size-list-md;
@include font-size-list-lg;
@include font-size-list-xl;

/* Custom Opacity */
$MIN_COUSTOM_OPACITY: 1;
$MAX_COUSTOM_OPACITY: 9;
@mixin opacity-list {
  @for $i from $MIN_COUSTOM_OPACITY through $MAX_COUSTOM_OPACITY {
    .opacity-0-#{$i} {
      opacity: #{$i*0.1}
    }
  }
}
@include opacity-list;

/* Custom Opacity */
$MIN_COUSTOM_LETTER_SPACING: 1;
$MAX_COUSTOM_LETTER_SPACING: 9;
@mixin letter-spacing-list {
  @for $i from $MIN_COUSTOM_LETTER_SPACING through $MAX_COUSTOM_LETTER_SPACING {
    .letter-spacing-0-#{$i} {
      letter-spacing: #{$i*0.1}px
    }
  }
}
@include letter-spacing-list;

.absolute-center-box {
  position: relative;
  overflow: hidden;
  .center-item {
    position: absolute;
    &.vertical-center {
      top: 50%;
      transform: translateY(-50%);
    }

    &.horizontal-center {
      left: 50%;
      transform: translateX(-50%);
    }
    &.center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.bottom {
      bottom: 0;
    }
  }
}

.font-weight-light-bold {
  font-weight: 600!important;
}

.font-weight-bold-extreme {
  font-weight: 900!important;
}

.cursor-pointer {
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 0px;
      .modal-header {
        padding: 0.5rem 1rem;
        position: relative;
        .modal-title {
          font-weight: 600;
          margin: auto;
        }
        button {
          position: absolute;
          right: 10px;
          top: 100%;
          transform: scale(1.5) translateY(-100%);
          margin: 0;
          padding: 0;
          &:focus, &:active {
            outline: none;
          }
        }
      }
      .modal-body {
        background-color: #fafafa;
        padding: 2rem 0px;
      }
      .modal-footer {
        .modal-sub-title {
          margin: auto;
        }

      }
    }
  }
}

.muted-link {
  &:hover, &:active, &:focus {
    text-decoration: none;
    border: none;
    outline: none;
  }
}

.text-underline {
  text-decoration: underline;
}

#icon-linkdin, #icon-twitter, #icon-github {
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.edit-btn {
  right: 50px;
  bottom: 100px;
  width: 60px;
  height: 60px;
  z-index: 100;
  background-color: #F4A619;
  box-shadow: 0 1px 1.5px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24);
}

.img-box{
  position: relative;
  height: 180px;
  overflow: hidden;
  &.large {
    height: 250px;
  }
  img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      width: 100%;
      height: 100%;
  }
}


*.disabled, *[disabled] {
  opacity: 0.5;
}

.btn-outline-primary {
  &:hover, &:focus {
    color: #ffffff;
  }
}

header {
  top: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
  z-index: map-get($z-layers, HEADER_ACTIVE);
  &.active{
    .side-menu {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      z-index: map-get($z-layers, SIDE_MENU);
      .mask{
        display: block;
      }
      .content {
        left: 0;
        -webkit-transition: left 0.5s;
        transition: left 0.5s;
      }
    }
  }
  .main-menu {
    height: 44px;
    .img-box{
      height: 33px;
      width: 33px;
      overflow: visible;
    }
    .hover-underline-btn {
      &:hover, &:active, &:focus {
        &::after {
          position: absolute;
          content: "";
          width: 70%;
          height: 3px;
          left: 50%;
          transform: translateX(-50%);
          bottom: -12px;
          background: #ffc72c;
        }
      }
    }
  }
  .side-menu{
    .mask {
      display: none;
      background: rgba(black, 0.4);
    };
    .content{
      width: 320px;
      top: 0;
      left: -320px;
      -webkit-transition: left 0.1s;
      transition: left 0.1s;
      .item {
        &:hover {
          background-color: #eeeeee;
          .text-primary-darker {
            color: #dda600 !important;
          }
        }
      }
    }
  }
}

footer{
  a {
    &:hover, &:active, &:focus{
      color: #ffc72c!important;
      text-decoration: none;
      opacity: 0.9;
      svg {
        fill: #ffc72c;
      }
    }
  }
}

.raw-icon {
  display: inline-flex;
  align-self: center;
}
.raw-icon svg {
  height:1em;
  width:auto;
}
.raw-icon.svg-baseline svg {
  top: .125em;
  position: relative;
}

.icon-container {
  vertical-align: middle;
}
.icon-container > svg {
  display: inline;
  vertical-align: middle;
}

.event-detail {
  overflow: hidden;

  .detail {
      background-color: #f3f7f9;
  }
  .join-btn {
      background-color: #F4A619;
      &.disabled {
          background-color: rgba(0,0,0,0.12)!important;
      }
      &:hover, &:active, &:focus {
          background-color: #FB8C00;
      }
  }
  .attendees {
      background-color: #f3f7f9;
      .img-box {
          width: 60px;
          height: 60px;
      }
  }
  .payment {
      background-color: #f3f7f9;
      select {
          background-color: #f3f7f9;
          appearance: none;
          border-radius: 0px;
          -webkit-appearance: none;
          -webkit-border-radius: 0px;
          background-repeat: no-repeat;
          background-position: right;
          &:active, &:focus {
              outline: none!important;
              box-shadow: none!important;
              border-color: #ced4da;
          }
      }
  }
  #editor {
      height: 400px;
  }

  .info-checkbox {
      .content {
          display: none;
      }
      &.open {
          background: #dc3545;
          color: white;
          border: 1px solid  #dc3545;
          border-top: none;
          .custom-input {
              margin-top: 8px;
              margin-bottom: 8px;
              label {
                  color: white;
              }
          }
          .content {
              display: block;
              background: rgb(243, 247, 249);
          }
      }
  }
}

.main-view {
  margin-top: 44px;
}

.bg-primary-darker {
  background-color: #eeb71c !important;
}

.text-primary-darker {
  color: #eeb71c !important;
}