select.form-control {
    background-image: url("../image/icon/dropdown-triangle.svg");
}


.custom-input {
    input {
        padding-left: 0px;
        background-color: transparent;
        &:active, &:focus {
            outline: none!important;
            box-shadow: none!important;
            border-color: #d9d9d9;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font-style: italic;
            color: #dddddd;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            font-style: italic;
            color: #dddddd;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            font-style: italic;
            color: #dddddd;
        }
        &:-moz-placeholder { /* Firefox 18- */
            font-style: italic;
            color: #dddddd;
        }
        &:-webkit-autofill {
            background-color: white !important;
            -webkit-box-shadow: inset 0 0 0px 9999px white!important;
        }
    }
    &.dark {
        input {
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font-style: italic;
                color: $orange;
                font-weight: bold;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                font-style: italic;
                color: $orange;
                font-weight: bold;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                font-style: italic;
                color: $orange;
                font-weight: bold;
            }
            &:-moz-placeholder { /* Firefox 18- */
                font-style: italic;
                color: $orange;
                font-weight: bold;
            }
        }
    }
    &.check {
        position: relative;
        height: 2rem;
        display: flex;
        align-items: center;
        label {
            padding-left: 2rem;
            position: relative;
            z-index: 2;
            cursor: pointer;
            margin-bottom:0;
            color: #87898a;
        }
        span {
            content: "";
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            border: 2px solid #ccc;
            position: absolute;
            left: 0;
            transition: all 0.2s;
            z-index: 1;
            box-sizing: content-box;
            &::before {
                transform: rotate(-55deg);
                top: 1rem;
                left: 0.37rem;
                display: inline-block;
                background: #fff;
                width: 0;
                height: 0.2rem;
                position: absolute;
                content: "";
                transform-origin: 0% 0%;
            }
            &::after {
                transform: rotate(35deg);
                bottom: 0.35rem;
                left: 0.2rem;
                display: inline-block;
                background: #fff;
                width: 0;
                height: 0.2rem;
                position: absolute;
                transform-origin: 0% 0%;
                content: "";
            }
        }
        input {
            display: none;
            &:checked{
                & ~ span {
                    background: #dc3545;
                    border-color: #dc3545;
                    &::before {
                        width: 1rem;
                        height: 0.15rem;
                        transition: width 0.1s;
                        transition-delay: 0.3s;
                    }
                    &::after {
                        width: 0.4rem;
                        height: 0.15rem;
                        transition: width 0.1s;
                        transition-delay: 0.2s;
                    }
                }
            }
            &:disabled {
                & ~ span {
                    background: #ececec;
                    border-color: #dcdcdc;
                }
                & ~ label {
                    color: #dcdcdc;
                    &:hover {
                        cursor: default;
                    }
                }
            }
        }
    }
  }

  select.form-control {
    background-image: url("../image/icon/dropdown-triangle.svg");
  }